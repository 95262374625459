.breadcrumb-item {
  display: inline-block;
  font-size: 14px;
  text-transform: uppercase;
}

.breadcrumb-space:not(:last-child)::after {
  color: rgb(75 85 99);
  content: '/';
  text-decoration: none;
}
