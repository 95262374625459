::file-selector-button {
  display: none;
}

#info-icon:hover #info-panel {
  display: block;
}

.info-icon:hover .content-panel {
  display: none;
}

.tag{
  border: 1px solid rgba(37, 99, 235, 0.5);
  color: rgb(37 99 235)
}

.outside-circle {
  position: absolute;
  background: white;
  color: rgb(37 99 235);
  margin-left: -42px;
  height: 36px;
  margin-top: 28px;
  width: 46px;
  rotate: -90deg;
  z-index: 10;
  box-shadow: -2px -2px 10px 1px #cccccc;
  border-top: 1px solid rgb(37 99 235);
  border-left: 1px solid rgb(37 99 235);
  border-right: 1px solid rgb(37 99 235);

  /*
  &::after {
    content: '';
    position: absolute;
    width: 27px;
    height: 27px;
    right: -2px;
    bottom: -2px;
    border-radius: 3px;
    z-index: -1;
    background: white;
    border-bottom:  2px solid rgb(37 99 235);
    border-right: 2px solid rgb(37 99 235);
  }
  */
}

.outside-circle:hover {
  background: rgb(96 165 250);
  color: white;


  &::after {
    background: #7aadff;
  }
}

.treeview-closed.treeview-compressed {
  /* background: blue !important; */
  /* left: 600px; */
  width: 0;
  box-shadow: 0 0 rgba(10, 10, 10, 10%);
  /* margin-right: 100px; */
  padding: 0;
}

.treeview-compressed {
  position: absolute;
  width: 55%;
  transition: all 0.5s linear;
  /* background: red; */
}

.treeview .btn-default {
  border-color: #e3e5ef;
}

.treeview .btn-default:hover {
  background-color: #f7faea;
  color: #bada55;
}

.treeview ul {
  list-style: none;
  padding-left: 10px;
  /* padding-left: 10px; */
}

.treeview ul > li {
  padding: 32px 0 0 35px;
  position: relative;
}

.treeview ul > li:before {
  content: '';
  position: absolute;
  top: 0;
  left: -12px;
  border-left: 2px dashed #a2a5b5;
  /* width: 1px; */
  height: 100%;
}

.treeview ul > li:after {
  content: '';
  position: absolute;
  border-top: 2px dashed #a2a5b5;
  top: 50px;
  left: -12px;
  width: 69px;
}

.treeview ul li:last-child:before {
  top: 2px;
  height: 50px;
}

.treeview > ul > li:after,
.treeview > ul > li:last-child:before {
  content: unset;
}

.treeview > ul > li:before {
  top: 90px;
  left: 36px;
}

.treeview > ul > li:not(:last-child) > ul > li:before {
  content: unset;
}

.treeview > ul > li > .treeview__level_before {
  height: 60px;
  width: 60px;
  top: -9.5px;
  background-color: #54a6d9;
  border: 7.5px solid #d5e9f6;
  font-size: 22px;
}

.treeview > ul > li > ul {
  padding-left: 12px;
}

.treeview__level {
  padding: 7px;
  padding-left: 42.5px;
  display: block;
  font-weight: 700;
  position: relative;
  /* z-index: 1; */
}

.treeview__level_before {
  /* content: attr(data-level); */
  position: absolute;
  left: -27.5px;
  top: -6.5px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 55px;
  width: 55px;
  border-radius: 50%;
  border: 7.5px solid #e8e8e8;
  color: #fff;
  font-size: 20px;
  z-index: 1;
}

.treeview__level-btns {
  margin-left: 15px;
  display: inline-block;
  position: relative;
}

.treeview__level .level-same,
.treeview__level .level-sub {
  position: absolute;
  display: none;
  transition: opacity 250ms cubic-bezier(0.7, 0, 0.3, 1);
}

.treeview__level .level-same.in,
.treeview__level .level-sub.in {
  display: block;
}

.treeview__level .level-same.in .btn-default,
.treeview__level .level-sub.in .btn-default {
  background-color: #faeaea;
  color: #da5555;
}

.treeview__level .level-same {
  top: 0;
  left: 45px;
}

.treeview__level .level-sub {
  top: 42px;
  left: 0;
}

.treeview__level .level-remove {
  display: none;
}

.treeview__level.selected {
  background-color: #f9f9fb;
  box-shadow: 0 3px 15px 0 rgba(0, 0, 0, 0.1);
}

.treeview__level.selected .level-remove {
  display: inline-block;
}

.treeview__level.selected .level-add {
  display: none;
}

.treeview__level.selected .level-same,
.treeview__level.selected .level-sub {
  display: none;
}

.treeview .level-title {
  user-select: none;
}
