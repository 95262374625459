@tailwind base;
@tailwind components;
@tailwind utilities;

html {
    zoom: var(--zoom);
    padding-right: 0px !important;
}

:root {
    --zoom: 0.8; /* set the variable saying what the current zoom is */
}

body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
    'Droid Sans', 'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

*::-webkit-scrollbar {
    -webkit-appearance: none;
    height: 5px;
    width: 10px;
    transition: 0.2s ease;
}

*::-webkit-scrollbar-track {
    border-top: unset;
    height: 5px;
}

*::-webkit-scrollbar-thumb {
    background-color: rgba(156, 148, 148, 0.555);
    border-radius: 4px;
}

/** {*/
/*  scrollbar-color: #c6c6c9aa transparent;*/
/*  scrollbar-width: thin;*/
/*  scrollbar-gutter: auto;*/
/*}*/


